import logo from './logo.svg';
import './App.css';
import Counter from './features/counter/Counter';
function App() {
  return (
    <main className="App">
      <Counter/>
    </main>
  );
}

export default App;
